import { StyleDeclaration, StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import {
	ArrowContainer,
	ArrowContainerProps,
	PopoverAlign,
	PopoverPosition,
	PopoverState,
	Popover as ReactTinyPopover,
} from 'react-tiny-popover';
import { isIE11 } from '../../../models/Browser';
import { debounce } from '../../../models/UiUtils';
import { background, successTint } from '../../styles/colors';
import {
	contentBlueBackgroundColor,
	contentEmailGuideBackgroundColor,
	contentWhiteBackgroundColor,
	errorBackgroundColor,
	styleSheet,
} from './styles';

interface IProps {
	align?: Exclude<PopoverAlign, 'custom'>;
	anchor: React.ReactNode;
	anchorStyles?: StyleDeclarationValue[];
	arrow?: Partial<ArrowContainerProps>;
	arrowSize?: number;
	arrowStyles?: StyleDeclaration[];
	autoCloseOtherPopoversOnClick?: boolean;
	autoCloseOtherPopoversOnHover?: boolean;
	children: React.ReactNode;
	className?: string;
	contentStyle?: Partial<CSSStyleDeclaration>; // trigger
	contentStyles?: StyleDeclaration[];
	disabled?: boolean;
	dismissOnOutsideAction?: boolean;
	isOpen?: boolean;
	onRequestClose?(): void;
	placement?: Exclude<PopoverPosition, 'custom'>[];
	styles?: StyleDeclaration[];
	tipSize?: number;
	/**
	 * If true, hovering over the achnor triggers the popover to open, and hovering-out triggers close Note: setting this
	 * to true will force this component to ignore @param isOpen and @param onRequestClose.
	 */
	toggleOnHover?: boolean;
	transitionDuration?: number;
	type?: PopoverType;
}

interface IState {
	arrowColor?: string;
	containerClassName?: StyleDeclaration;
	isOpen?: boolean;
}

export enum PopoverType {
	custom = 0,
	error,
	white,
	emailGuide,
	blue,
	lightBlue,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	background,
	errorSecondary,
	success,
}

const getStylesByType = (type: PopoverType) => {
	switch (type) {
		case PopoverType.success: {
			return {
				arrowColor: successTint,
				containerClassName: styleSheet.popoverContentSuccess,
			};
		}
		case PopoverType.error: {
			return {
				arrowColor: errorBackgroundColor,
				containerClassName: styleSheet.popoverContentError,
			};
		}
		case PopoverType.errorSecondary: {
			return {
				arrowColor: errorBackgroundColor,
				containerClassName: styleSheet.popoverContentErrorSecondary,
			};
		}
		case PopoverType.white: {
			return {
				arrowColor: contentWhiteBackgroundColor,
				containerClassName: styleSheet.popoverContentWhite,
			};
		}
		case PopoverType.background: {
			return {
				arrowColor: background,
				containerClassName: styleSheet.popoverContentBackground,
			};
		}
		case PopoverType.blue: {
			return {
				arrowColor: contentBlueBackgroundColor,
				containerClassName: styleSheet.popoverContentBlue,
			};
		}
		case PopoverType.lightBlue: {
			return {
				arrowColor: contentBlueBackgroundColor,
				containerClassName: styleSheet.popoverContentLightBlue,
			};
		}
		case PopoverType.emailGuide: {
			return {
				arrowColor: contentEmailGuideBackgroundColor,
				containerClassName: styleSheet.popoverContentEmailGuide,
			};
		}
		default: {
			return null;
		}
	}
};

export class TinyPopover extends React.Component<IProps, IState> {
	public static CloseEventName = 'TinyPopoverCloseEvent';

	public static defaultProps: IProps = {
		anchor: null,
		autoCloseOtherPopoversOnClick: true,
		children: null,
		type: PopoverType.custom,
	};

	public static getDerivedStateFromProps(props: IProps, state: IState) {
		const nextState: IState = {};

		if (!props.toggleOnHover && state.isOpen !== props.isOpen) {
			nextState.isOpen = props.isOpen;
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	constructor(props: IProps) {
		super(props);

		this.state = {
			isOpen: !props.toggleOnHover ? props.isOpen : false,

			...getStylesByType(props.type),
		};
	}

	public componentDidMount() {
		if (this.props.dismissOnOutsideAction) {
			this.watchForOutsideAction();
		}

		window.addEventListener(TinyPopover.CloseEventName, this.onOuterAction);
	}

	public componentWillUnmount() {
		if (this.props.dismissOnOutsideAction) {
			this.stopWatchingForOutsideAction();
		}

		window.removeEventListener(TinyPopover.CloseEventName, this.onOuterAction);
	}

	public render() {
		const { align, anchor, anchorStyles, autoCloseOtherPopoversOnClick, contentStyle, disabled, placement, styles } =
			this.props;
		const { isOpen } = this.state;
		return (
			<ReactTinyPopover
				align={align || 'start'}
				containerClassName={css(styleSheet.tinyPopover, ...(styles || []))}
				containerStyle={{ ...contentStyle, opacity: !isOpen ? '0' : '1' }}
				content={this.onRenderContent}
				isOpen={!disabled && isOpen}
				positions={placement || ['bottom', 'top']}
			>
				<div
					className={`tinyPopover-anchor ${css(styleSheet.anchorContainer, ...(anchorStyles || []))}`}
					onClick={!disabled && !!autoCloseOtherPopoversOnClick ? this.closeOtherPopovers : undefined}
					onMouseDown={!disabled && !!autoCloseOtherPopoversOnClick ? this.closeOtherPopovers : undefined}
					onMouseEnter={this.onAnchorHover(true)}
					onMouseLeave={this.onAnchorHover(false)}
					onTouchStart={!disabled && !!autoCloseOtherPopoversOnClick ? this.closeOtherPopovers : undefined}
				>
					{anchor}
				</div>
			</ReactTinyPopover>
		);
	}

	private onAnchorHover = (over: boolean) => () => {
		const { disabled, autoCloseOtherPopoversOnHover, toggleOnHover } = this.props;

		if (disabled) {
			return;
		}

		if (toggleOnHover) {
			this.setState({ isOpen: over });
		}

		if (autoCloseOtherPopoversOnHover) {
			this.closeOtherPopovers();
		}
	};

	private closeOtherPopovers = () => {
		window.removeEventListener(TinyPopover.CloseEventName, this.onOuterAction);
		let event: Event;
		if (isIE11()) {
			event = document.createEvent('Event');
			event.initEvent(TinyPopover.CloseEventName, true, true);
		} else {
			event = new CustomEvent(TinyPopover.CloseEventName);
		}
		window.dispatchEvent(event);
		window.addEventListener(TinyPopover.CloseEventName, this.onOuterAction);
	};

	private onOuterAction = () => {
		if (this.state.isOpen) {
			const { onRequestClose, toggleOnHover } = this.props;
			if (!toggleOnHover && onRequestClose) {
				onRequestClose();
			} else {
				this.setState({ isOpen: false });
			}
		}
	};

	private onRenderContent = ({ position, childRect, popoverRect }: PopoverState) => {
		const { arrow, arrowStyles = [], children, contentStyles, arrowSize, className } = this.props;
		const { arrowColor, containerClassName } = this.state;

		return (
			<ArrowContainer
				arrowColor={arrowColor || 'white'}
				arrowSize={arrowSize ?? 0}
				arrowStyle={{ display: 'none' }}
				childRect={childRect}
				className={`tiny-popover-arrow ${css((arrow || arrowStyles.length) && styleSheet.arrow, ...arrowStyles)}`}
				popoverRect={popoverRect}
				position={position}
				{...(arrow || {})}
			>
				<div
					className={`tinyPopover-content ${className} ${css(containerClassName, ...(contentStyles || []))}`}
					onClick={this.stopPropagation}
					onMouseDown={this.stopPropagation}
					onTouchStart={this.stopPropagation}
				>
					{children}
				</div>
			</ArrowContainer>
		);
	};

	private stopPropagation = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
		e.stopPropagation();
	};

	private stopWatchingForOutsideAction = () => {
		window.removeEventListener('mousedown', this.onOuterAction);
		window.removeEventListener('touchstart', this.onOuterAction);
		window.removeEventListener('resize', debounce(this.onOuterAction, 25));
	};

	private watchForOutsideAction = () => {
		/**
		 * React-tiny-popover's default outside action tracking is insufficient. adding this to ensure that popover is
		 * dismissed as expected (similar to how old react-popover was tracking this)
		 */
		window.addEventListener('mousedown', this.onOuterAction);
		window.addEventListener('touchstart', this.onOuterAction);
		window.addEventListener('resize', debounce(this.onOuterAction, 25));
	};
}
